/* ATEN��O!
Esta aplica��o foi desenvolvida em car�ter de urgencia, devido a presen�a do Novo Corona V�rus,  causador da Covid-19, com o int�ito de rastrear a ocorr�ncia da doen�a no entre a equipe do Minist�rio P�blico do Rio de Janeiro.  Devido a urgencia, utilizou-se um c�digo que estava sendo utilizado para outros fins.  Por isso, um monte de arquivos e fun��es n�o s�o utilizadas nesta aplica��o, ainda que presentes no c�digo.
Adaptado por: Charles Robbs - 30-03-2019
*/

import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';

//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Observable } from 'rxjs';
import { PassaObjetoService } from './services/passa-objeto.service';
import { NavController, MenuController } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { map } from 'rxjs/operators';
import { AuthService } from './services/user/auth.service';
import { NavigationExtras } from '@angular/router';
import { Router } from '@angular/router';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  usuario: any
  visitante = true
  rootPage: any = 'BemVindoPage';
  emConstrucao = false
  constructor(
    private platform: Platform,
    private menu: MenuController,
    private navCtrl: NavController,
    private router: Router,
    public authService: AuthService,
    private passaObjetoService: PassaObjetoService,

  ) {
    this.initializeApp();
    // console.log(this.authService.usuarioLogado)
    this.passaObjetoService.navParams = {emConstrucao: this.emConstrucao }
   
  }

  initializeApp() {

    let errorCb
    var subs = this.authService.getUserData()
      .subscribe(data => {
        // console.log("data", data)
        if (data !== undefined && data.toString() !== "UNAUTHORIZED") {
          this.usuario = data
          this.visitante = false
          // console.log("data", data)
        } else {
          return
        }
      },
        error => {
          console.log("error", error)
        },
        () => {
          //this gets called on completion, callback code comes here
          console.log("finish")

        }
      );







    this.platform.ready().then(() => {
      //   this.navCtrl.navigateRoot('/agenda-lista');
      //this.navCtrl.navigateRoot('/agenda-lista');
      //  this.statusBar.styleDefault();
      // this.splashScreen.hide();

      this.router.navigateByUrl('/bem-vindo');
    });

    SplashScreen.hide().catch(error => {
      console.error(error);
    });



  }
  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  liberaConteudo(){
    this.passaObjetoService.navParams = {emConstrucao: false }
    this.initializeApp()
  }
  

  openEnd() {
    this.menu.open('end');
  }

  openCustom() {
    this.menu.enable(true, 'custom');
    this.menu.open('custom');
  }

 

  toggleMenu() {
    this.menu.toggle(); //Add this method to your button click function
  }


  openPerfil() {
    //  this.navCtrl.navigateForward('/perfil-usuario/' + this.idAtual);
    let navigationExtras: NavigationExtras = { state: { id: this.usuario.codusuario } };
    this.router.navigate(['/perfil-usuario'], navigationExtras);
  }
  logout() {
    this.authService.logoutUsuario().subscribe(data => {
      let message
      if (data['success'] == true) {
        localStorage.removeItem('cvusr');
        this.setCookie('usrmat', null, 0)
        message = "Sucesso"
        window.location.reload()
      } else {
      }
    })
  }
  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
}
