import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },

  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'bem-vindo',
    loadChildren: () => import('./bem-vindo/bem-vindo.module').then( m => m.BemVindoPageModule)
  },
  
  {
    path: 'novo-saude',
    loadChildren: () => import('./saude/novo-saude/novo-saude.module').then( m => m.NovoSaudePageModule)
  },
  {
    path: 'saude-detalhes',
    loadChildren: () => import('./saude/saude-detalhes/saude-detalhes.module').then( m => m.SaudeDetalhesPageModule)
  },
  {
    path: 'saude-lista',
    loadChildren: () => import('./saude/saude-lista/saude-lista.module').then( m => m.SaudeListaPageModule)
  },
  {
    path: 'novo-usuario',
    loadChildren: () => import('./usuarios/novo-usuario/novo-usuario.module').then( m => m.NovoUsuarioPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./usuarios/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'login/:login',
    loadChildren: () => import('./usuarios/login/login.module').then( m => m.LoginPageModule)
  },
  
  {
    path: 'reset-password',
    loadChildren: () => import('./usuarios/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'novo-vacina',
    loadChildren: () => import('./vacina/novo-vacina/novo-vacina.module').then( m => m.NovoVacinaPageModule)
  },
  {
    path: 'vacina-detalhes',
    loadChildren: () => import('./vacina/vacina-detalhes/vacina-detalhes.module').then( m => m.VacinaDetalhesPageModule)
  },
  {
    path: 'novo-exame',
    loadChildren: () => import('./exame/novo-exame/novo-exame.module').then( m => m.NovoExamePageModule)
  },
  {
    path: 'exame-detalhes',
    loadChildren: () => import('./exame/exame-detalhes/exame-detalhes.module').then( m => m.ExameDetalhesPageModule)
  },
  {
    path: 'confirmacao',
    loadChildren: () => import('./usuarios/confirmacao/confirmacao.module').then( m => m.ConfirmacaoPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
