import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { from, Observable, throwError, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import jwt_decode from "jwt-decode";
import { PassaObjetoService } from '../../services/passa-objeto.service'
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpErrorResponse,
  HttpEventType,
  // tslint:disable-next-line: import-spacing
} from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public token_autorize;
  public baseUrl = environment.baseURL
  public usuario: any

  public usuarioLogado: any

  // public cdmatricula = JSON.parse(localStorage.getItem("cvusr"))

  public cdmatricula


  constructor(private HttpClient: HttpClient, private router: Router, public PassaObjetoService: PassaObjetoService) {
   // this.cdmatricula = this.PassaObjetoService.getMatUser()
 //   console.log(this.cdmatricula)
    this.setUsuario("UNAUTHORIZED")
  //  console.log(this.getCookie('usrmat'))
    this.cdmatricula =this.getCookie('usrmat')
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError(this.handleError)
      )
  };


  cadastrarUsuario(e) {
    let configUrl = environment.userUrl + '/register';
    return this.HttpClient.post(configUrl, e, {
      reportProgress: true,
      observe: 'events'
    });

  }

  public getToken(e) {

    return this.HttpClient.post(this.baseUrl + "/user/login", e, { observe: 'response' })
  }

  public getAuth(token): Observable<any> {

    // // console.log('Body Token', token);

    this.token_autorize = jwt_decode(token);
    let httpHeaders = new HttpHeaders();
    httpHeaders.append('Authorization', 'Bearer ' + token);
    httpHeaders.append('Content-Type', 'application/json');
    httpHeaders.append("Accept", "*/*")

    var reqHeader = new HttpHeaders({
      "Authorization": "Bearer " + token,
      "Content-Type": "application/json",
      "Accept": "*/*"
    });

    let body = { "application": this.token_autorize.appId, "role": this.token_autorize.roles[0] } //{"application": "GATE", "role": "Gestor"}//

    // console.log('header', reqHeader);
    let options2 = { headers: httpHeaders };
    // console.log('BODY', body);    

    return this.HttpClient.post(this.baseUrl + "/user/authorizeRole", body, options2)
  }


  loginUsuario(e) {
    let configUrl = environment.userUrl + '/login';
    return this.HttpClient.post(configUrl, e)
  }


  logoutUsuario() {
    return this.HttpClient.post(this.baseUrl + "/user/logout", null)
  }

  getUserData(): Observable<any> {
 //    console.log(this.cdmatricula)
    let loginType
    if (this.cdmatricula !== undefined && this.cdmatricula !== null  && this.cdmatricula !== '') {

      let configUrl
      if (this.cdmatricula == "database") {
        loginType = "database"
        configUrl = environment.userUrl + '/getUser';
      } else {
        loginType = "sca"
        configUrl = environment.userUrl + '/getUserScaM?cdmatricula=' + this.cdmatricula;
      }


      return this.HttpClient.get(configUrl).pipe(
        map((usr) => {
     //      console.log(usr)

          let usrF
          if (loginType === "sca") {
            usrF = usr[0]
          } else {
            usrF = usr
          }
          this.setUsuario(usrF)
          return usrF
        }), catchError(error => {
          return throwError('Something went wrong!', error);
        })
      )
    } else {
      return throwError('Something went wrong!');
    }
  }

  public setUsuario(usuario) {
    this.usuario = new Observable(function (usr) {
      //  // console.log(usuario);
      usr.next(usuario);
    });

  }

  resetPassword(e) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let configUrl = environment.userUrl + '/resetPassword';
    return this.HttpClient.post(configUrl + `?email=${e}`, null);
  }

  updatePassword(pwd, token) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let configUrl = environment.userUrl + '/updatePassword';
    return this.HttpClient.post(configUrl + `?password=${pwd}&token=${token}`, null);
  }

  //comvc/user/updatePassword

  SendVerificationMail(email) {
    // console.log(email)
    let configUrl = environment.userUrl + '/sendEmail';
    return this.HttpClient.post(configUrl + `?email=${email}`, null);
  }




}
