import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PassaObjetoService {
  private destn: any;
  public navParams: any
  public matUser: any
  constructor() { }

  public setDestn(destn) {
    this.destn = destn;
    console.log(this.destn)
  }

  public setMatUser(matUser) {
    this.matUser = matUser;
    console.log(this.matUser)
  }
  getMatUser() {
    return this.matUser;
  }
  getDestn() {
    return this.destn;
  }
}
